import { ConnectorNames, Flex, Text, useWalletModal } from '@library/uikit'
import { useTranslation } from '@library/localization'
import { formatDistanceToNow } from 'date-fns'
import { enUS, ja, zhCN } from 'date-fns/locale'
import NextImage from 'next/image'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useWallet from 'hooks/useWallet'
import useLogin from 'hooks/game/useLogin'
import useAuth from '../../../hooks/useAuth'
import { useDagenApi } from '../../../hooks/useDagenApi'
import { useProfile, useProfileForAddress } from '../../../state/profile/hooks'
import { FlexGap } from '../../Layout/Flex'

const Container = styled(Flex)`
  z-index: 10;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 16px;
  }

  img {
    cursor: pointer;

    &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
      opacity: 0.65;
    }

    &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
      opacity: 0.85;
      transform: translateY(1px);
      box-shadow: none;
    }
  }

  .energyIconId {
    animation-duration: 25s;
  }

  .energyDoneId {
    animation-duration: 25s;
  }
`

const EnergyText = styled(Text)`
  background-color: #f5e128;
  padding: 0 10px;
  color: #000;
  border-radius: 20px;
  font-size: 10px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 5px 10px;
    color: #000;
    border-radius: 20px;
    font-size: 14px;
  }
`

const EnergySpan = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  padding-right: 5px;
`

interface Profile {
  address: string
  energy: number
  availableEnergy: number
  expireTime: number
}

interface ClockInProps {
  accountPath: string
}

const ClockIn: React.FC<ClockInProps> = ({ accountPath }) => {
  const ENERGY_ANIMATIONS = ['animate__animated', 'animate__shakeY', 'animate__infinite']
  const ENERGY_ICON_ID = 'energyIconId'
  const ENERGY_DONE_ID = 'energyDoneId'
  const ENERGY_ICON_NO_LOGIN_ID = 'energyIconNoLoginId'
  const LANGUAGE2CODE = { 'zh-cn': zhCN, en: enUS, ja }
  const { t, currentLanguage } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)
  const { code } = currentLanguage
  const dagenApi = useDagenApi()
  const { walletAddress } = useWallet()
  const { refresh } = useProfile()
  const { refresh: refreshProfile } = useProfileForAddress(walletAddress)
  const { startLogin } = useLogin()

  const [showDone, setShowDone] = useState(false)
  const [profile, setProfile] = useState<Profile>(null)
  const [availableEnergy, setAvailableEnergy] = useState(0)
  const [expireTime, setExpireTime] = useState(0)
  const [expireTimeDesc, setExpireTimeDesc] = useState(null)
  const timer = useRef<NodeJS.Timeout>()

  const getProfileByAddress = useCallback(async () => {
    if (!walletAddress) {
      return
    }
    const res = await dagenApi.get(`availableEnergy`, { params: { address: walletAddress } })
    if (!res.data.success) {
      setProfile(null)
      return
    }
    const profile = res.data.result
    if (!profile) {
      return
    }
    setProfile(profile)
    setShowDone(isSign(profile))
    setExpireTime(profile.expireTime)
    setAvailableEnergy(profile.availableEnergy)
  }, [dagenApi, walletAddress])

  useEffect(() => {
    if (!walletAddress || walletAddress.toLowerCase() !== accountPath.toLowerCase()) {
      setProfile(null)
    } else {
      getProfileByAddress()
    }
  }, [walletAddress, accountPath, getProfileByAddress])

  useEffect(() => {
    const energyDoneElement = document.getElementById(ENERGY_DONE_ID)
    const energyIconElement = document.getElementById(ENERGY_ICON_ID)
    const energyIconNoLoginIdElement = document.getElementById(ENERGY_ICON_NO_LOGIN_ID)

    if (energyIconNoLoginIdElement) {
      energyIconNoLoginIdElement.classList.remove(...ENERGY_ANIMATIONS)
      energyIconNoLoginIdElement.classList.add(...ENERGY_ANIMATIONS)
    }

    if (!showDone && energyIconElement) {
      energyIconElement.classList.remove(...ENERGY_ANIMATIONS)
      energyIconElement.classList.add(...ENERGY_ANIMATIONS)
    }

    if (showDone && energyDoneElement) {
      energyDoneElement.classList.remove(...ENERGY_ANIMATIONS)
      energyDoneElement.classList.add(...ENERGY_ANIMATIONS)
    }
  }, [showDone, profile])

  useEffect(() => {
    const calculate = async () => {
      if (expireTime - Date.now() <= 0) {
        setExpireTime(0)
        setShowDone(false)

        await getProfileByAddress()
      }
    }
    if (expireTime - Date.now() > 0) {
      calculate()
      timer.current = setInterval(calculate, 1000)
      setExpireTimeDesc(formatDistanceToNow(expireTime, { locale: LANGUAGE2CODE[code] }))
    }
    return () => clearTimeout(timer.current)
  }, [expireTime, code, getProfileByAddress])

  useEffect(() => {
    const autoClock = JSON.parse(localStorage.getItem('market_auto_clock')) ?? false
    if (autoClock && walletAddress && profile?.address) {
      onClockIn()
      localStorage.setItem('market_auto_clock', 'false')
    }
  }, [walletAddress, profile?.address])

  const onClockIn = async () => {
    if (isSign(profile)) {
      return
    }
    const res = await dagenApi.get('clockIn', { params: { address: walletAddress } })
    if (!res.data.success) {
      return
    }
    const profileResult = res.data.result
    if (!profileResult) {
      return
    }
    await refresh()
    await refreshProfile()
    setProfile(profileResult)
    setShowDone(true)
    setExpireTime(profileResult.expireTime)
  }

  const onAutoClock = async () => {
    localStorage.setItem('market_auto_clock', 'true')
    startLogin()
  }

  function isSign(profile: Profile) {
    return Date.now() < profile.expireTime
  }

  return (
    <>
      {!profile && (
        <Container>
          <FlexGap
            gap="10px"
            onClick={onAutoClock}
            alignItems="center"
            id={ENERGY_ICON_NO_LOGIN_ID}
            className={ENERGY_ICON_ID}
          >
            <NextImage width={48} height={48} src="/images/energy-icon.png" className="image-drag-none" />
            <EnergyText>{t('Login to receive energy')}</EnergyText>
          </FlexGap>
        </Container>
      )}
    </>
  )
}

export default ClockIn
