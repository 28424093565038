import { Flex, TextItem, useMatchBreakpoints } from '@library/uikit'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import ClickImage from '@library/uikit/src/components/Image/ClickImage'
import ImageIcon from '@library/uikit/src/components/Image/ImageIcon'
import { TextTitle } from '@library/uikit/src/components/Text/Text'
import { baseColors } from '@library/uikit/src/theme/colors'

import { useTranslation } from '@library/localization'
import { CHAINBOW_URL, discordUrl, NOTESV_URL, twitterUrl, WALLET3_URL } from '@library/uikit/src/widgets/Menu/config'
import NextImage from 'next/image'
import React from 'react'
import styled from 'styled-components'
import { FlexGap } from '../../../../components/Layout/Flex'

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  position: relative;
`

const BottomContainer = styled(Flex)<{ isMobile: boolean }>`
  flex-direction: column;
  align-items: center;
  position: relative;
  height: ${({ isMobile }) => (isMobile ? 'auto' : '3800px')};

  @media (max-height: 875px) {
    height: ${({ isMobile }) => (isMobile ? 'auto' : '3400px')};
  }
`

export const ChatButton = styled(ClickFlex)<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 18px;
  border-radius: 8px;
  z-index: 10;
  height: 48px;
  width: 135px;
  gap: 5px;
`

export const MemberImage = styled(Flex)<{ isMobile: boolean }>`
  align-items: center;
  justify-content: center;
`

export const MemberFlex = styled(FlexGap)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70px;
  padding: 0 5px;
  gap: 5px;

  div {
    font-size: 10px;
    font-weight: 400;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0 5px;
    text-align: center;
    gap: 20px;

    div {
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export const TeamContainer = styled(Flex)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '' : '20px')} ${({ isMobile }) => (isMobile ? '' : '200px')};
  border-radius: 50px;
  flex-direction: column;
  align-items: center;
  background-color: ${({ isMobile }) => (isMobile ? '' : 'rgb(0, 0, 0, 0.3)')};
`

const MediaContainer: React.FC = () => {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const openURL = (url: string) => window.open(url, '_blank')?.focus()
  return (
    <Flex width="100%" pt={18} flexDirection="column" alignItems="center">
      <TextItem fontWeight={600} fontSize={isMobile ? '16px' : '22px'}>
        {t('JoinUs')}
      </TextItem>
      <FlexGap pt={13} gap="15px" flexDirection="row" justifyContent="center">
        <ChatButton onClick={() => openURL(twitterUrl)} backgroundColor={baseColors.wathet}>
          <ImageIcon width={25} height={25} src="/images/twitter.svg" />
          <span style={{ fontWeight: 600 }}>Twitter</span>
        </ChatButton>

        <ChatButton onClick={() => openURL(discordUrl)} backgroundColor={baseColors.blue}>
          <ImageIcon width={25} height={25} src="/images/discord.svg" mt="5px" />
          <span style={{ fontWeight: 600 }}>Discord</span>
        </ChatButton>
      </FlexGap>
    </Flex>
  )
}

const HomeBottomContainer: React.FC = () => {
  const { isMobile } = useMatchBreakpoints()
  const openURL = (url: string) => window.open(url, '_blank')?.focus()
  const { t } = useTranslation()
  const teamDesc = [t('TeamDesc1'), t('TeamDesc2'), t('TeamDesc3'), t('TeamDesc4')]
  const memberInfos = [
    {
      title: t('MainMember'),
      members: [
        'Long Li',
        'YANG Nan',
        'Shinichi Chiaki',
        'Mai',
        'Xueming Sun',
        'Masaya Yoshikawa',
        'Hiroto Shibasaki',
        'Yasuko Shiokawa',
      ],
    },
    {
      title: t('AdvisoryMember'),
      members: ['Hirokazu Kono', 'Akira Morikawa', 'Hideto Arai', 'Jeffery Lei'],
    },
  ]

  const productInfos = [
    { url: CHAINBOW_URL, image: '/images/chainbow-wallet.png' },
    { url: WALLET3_URL, image: '/images/wallet3.png' },
    { url: NOTESV_URL, image: '/images/note-sv.png' },
  ]

  return (
    <>
      <BottomContainer pb={[222, 222, 0, 0]} isMobile={isMobile}>
        <Container>
          <TextTitle fontSize="24px">{t('ExchangeToken')}</TextTitle>
          <Flex pt={40} flexDirection="row" justifyContent="center" width={['70%', '70%', '60%', '60%']}>
            <Flex mr={['8%', '8%', 80, 80]}>
              <NextImage width={160} height={160} src="/images/uni-icon.png" quality={50} />
            </Flex>

            <Flex>
              <NextImage width={160} height={160} src="/images/bibox-icon.png" quality={50} />
            </Flex>

            <Flex ml={['8%', '8%', 80, 80]}>
              <NextImage width={160} height={160} src="/images/opensea-icon.png" quality={50} />
            </Flex>
          </Flex>

          <TeamContainer mt={[90, 90, 335, 335]} mb={[50, 50, 240, 240]} isMobile={isMobile}>
            <TextTitle>{t('Team')}</TextTitle>

            {teamDesc.map((textItem, index) => {
              return (
                <TextItem key={index} isMobile={isMobile} fontSize="16px" fontWeight={400}>
                  {textItem}
                </TextItem>
              )
            })}

            {/* {memberInfos.map((memberInfo, index) => {
              return (
                <Flex
                  key={memberInfo.title}
                  width="100%"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mt={index === 0 ? 73 : 109}
                >
                  <TextTitle mb={30} fontSize="22px">
                    {memberInfo.title}
                  </TextTitle>
                  <Grid
                    gridTemplateColumns={['repeat(4, 1fr)', 'repeat(4, 1fr)', 'repeat(4, 1fr)', 'repeat(4, 1fr)']}
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                    key={memberInfo.title}
                    gridRowGap="45px"
                    gridColumnGap={['20px', '20px', '40px', '40px']}
                  >
                    {memberInfo.members.map((member, index) => {
                      return (
                        <MemberFlex key={member}>
                          <NextImage src={`/images/member/${member}.png`} width={150} height={186} />
                          <Text height={40} textAlign="center">
                            {member}
                          </Text>
                        </MemberFlex>
                      )
                    })}
                  </Grid>
                </Flex>
              )
            })} */}

            <TextTitle pb={20} pt={107} fontSize="24px">
              {t('Our Products')}
            </TextTitle>

            <FlexGap gap="12px" flexDirection="row" justifyContent="center" width="100%" alignItems="center">
              {productInfos.map((product, index) => {
                return (
                  <ClickImage
                    key={product.url}
                    onClick={() => openURL(product.url)}
                    width={isMobile ? 76 : 150}
                    height={isMobile ? 76 : 150}
                    src={product.image}
                  />
                )
              })}
            </FlexGap>
          </TeamContainer>
        </Container>
        <Flex style={{ position: 'absolute', bottom: 0, zIndex: -1 }} className="image-drag">
          <div style={{ position: 'relative' }}>
            <NextImage src="/images/bg-bottom-1.png" width={1920} height={2560} quality={10} />
            <div style={{ position: 'absolute', bottom: 0 }}>
              <NextImage src="/images/bg-bottom.png" width={1920} height={900} quality={10} />
            </div>
          </div>
        </Flex>
      </BottomContainer>

      <MediaContainer />
    </>
  )
}

export default HomeBottomContainer
