import { FetchStatus } from 'config/constants/types'
import { useDagenApi } from 'hooks/useDagenApi'
import useSWRImmutable from 'swr/immutable'
import type { Setting } from '../../../server/model'

export const useSetting = () => {
  const dagenApi = useDagenApi()

  const {
    data: setting,
    status,
    mutate,
  } = useSWRImmutable<Setting>(`querySetting`, async () => {
    const res = await dagenApi.get(`querySetting`)
    return res.data.result
  })

  const isLoading = status === FetchStatus.Fetching
  const isInitialized = status === FetchStatus.Fetched || status === FetchStatus.Failed

  return { setting, isInitialized, isLoading, refresh: mutate }
}
