import { Flex, TextItem } from '@library/uikit'
import { TextTitle } from '@library/uikit/src/components/Text/Text'
import { ColumnCenter } from 'components/Layout/Column'
import { useTranslation } from '@library/localization'
import React, { useEffect, useState } from 'react'
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react'

const HomeEconomicContainer: React.FC = () => {
  const videoPrefix = '/gens_'
  const posterPrefix = '/images/economic-bg-'
  const [genVideoUrl, setGenVideoUrl] = useState('')
  const [poster, setPoster] = useState('')
  const { t, currentLanguage } = useTranslation()

  useEffect(() => {
    setGenVideoUrl(`${videoPrefix + currentLanguage.code}.mp4`)
    setPoster(`${posterPrefix}en.png`)
  }, [currentLanguage])

  return (
    <Flex mt={[null, null, '-20vh', '-30vh']} pb="20%">
      {poster && (
        <ColumnCenter style={{ zIndex: 12 }}>
          <ColumnCenter>
            <TextTitle>{t('EconomicSystem')}</TextTitle>
            <TextItem pb={5} fontSize="16px" fontWeight={400}>
              {t('EconomicSystemDesc')}
            </TextItem>
          </ColumnCenter>
          <Flex
            position="relative"
            px={['3%', '3%', '10%', '10%']}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Player key={currentLanguage.code} preload="none" poster={poster}>
              <source src={genVideoUrl} />
              <BigPlayButton position="center" />
              <ControlBar>
                <VolumeMenuButton vertical />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
              </ControlBar>
            </Player>
          </Flex>
        </ColumnCenter>
      )}
    </Flex>
  )
}

export default HomeEconomicContainer
