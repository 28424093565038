import { Box, Button, Flex, Image, Text, useMatchBreakpoints } from '@library/uikit'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import ClickImage from '@library/uikit/src/components/Image/ClickImage'
import { LANGUAGE2CODE } from '@library/uikit/src/widgets/Menu/config'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from '@library/localization'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { useSetting } from 'state/global/hooks'
import styled from 'styled-components'
import { FlexGap } from '../../../../components/Layout/Flex'
import useWindowSize from '../../../../hooks/useWindowSize'

const Container = styled(Flex)<{ isMobile: boolean }>`
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bg-img {
    filter: ${({ isMobile }) => (isMobile ? 'brightness(50%)' : 'opacity(80%)')};
    z-index: -1;
  }
`

const CountdownContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const CountdownItemContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: -1;
`

const BorderCountdownButton = styled(Flex)<{
  borderRadius?: string
  background?: string
  borderColor?: string
  border?: string
}>`
  border-radius: ${({ borderRadius }) => borderRadius || '15px'};

  pointer-events: none;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: white;
  font-weight: bold;
  background: ${({ background }) => background || '#282C39'};
  box-shadow: 0 0 0 0.4rem white, 0 0 0 0.5rem black, 0 0 0 0.8rem #ff6b00;
  width: 80px;
  height: 80px;
  margin-bottom: 5px;

  @media screen and (max-width: 700px) {
    font-size: 24px;
    box-shadow: 0 0 0 4px white, 0 0 0 6px black, 0 0 0 10px #ff6b00;
    border-radius: ${({ borderRadius }) => borderRadius || '4px'};
    width: 60px;
    height: 60px;
  }

  @media screen and (max-width: 500px) {
    box-shadow: 0 0 0 4px white, 0 0 0 6px black, 0 0 0 10px #ff6b00;
    border-radius: ${({ borderRadius }) => borderRadius || '4px'};
    width: 50px;
    height: 50px;
  }

  @media screen and (max-width: 370px) {
    box-shadow: 0 0 0 2px white, 0 0 0 3px black, 0 0 0 6px #ff6b00;
    border-radius: ${({ borderRadius }) => borderRadius || '3px'};
    width: 45px;
    height: 45px;
  }
`

const SloganContainer = styled(Flex)<{ igoStatus: number; imageUrl: string; widthScreen?: number }>`
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 0;
  background-image: url('${({ imageUrl }) => imageUrl}');

  width: ${({ igoStatus }) => (igoStatus === 0 ? '575px' : '720px')};
  height: ${({ igoStatus }) => (igoStatus === 0 ? '506px' : '720px')};
  @media screen and (max-width: 700px) {
    width: ${({ igoStatus }) => (igoStatus === 0 ? '575px' : '680px')};
    height: ${({ igoStatus }) => (igoStatus === 0 ? '506px' : '680px')};
    margin-top: ${({ igoStatus }) => (igoStatus !== 1 ? '120px' : '50px')};
    margin-bottom: 30px;
  }

  // 处理倒计时
  @media screen and (max-width: 575px) {
    background-image: url('${({ igoStatus, imageUrl }) =>
      igoStatus === 0 ? imageUrl : '/images/gens-slogan-3-mobile.png'}');
    width: ${({ igoStatus, widthScreen }) => (igoStatus !== 0 ? `${widthScreen - 1}px` : '400px')};
    height: ${({ igoStatus, widthScreen }) => (igoStatus !== 0 ? `${((widthScreen - 1) / 720) * 800}px` : '350px')};
  }

  @media screen and (max-width: 550px) {
    width: ${({ igoStatus, widthScreen }) => (igoStatus !== 0 ? `${widthScreen - 1}px` : '400px')};
    height: ${({ igoStatus, widthScreen }) => (igoStatus !== 0 ? `${((widthScreen - 1) / 720) * 800}px` : '350px')};
    margin-top: ${({ igoStatus }) => (igoStatus !== 1 ? '120px' : '50px')};
    margin-bottom: 100px;
  }
`

const PSGMobileContainer = styled(Flex)`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(30, 33, 34, 0.5);
`

const PSGContainer = styled(Flex)`
  width: 100%;
  height: 480px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(30, 33, 34, 0.8);
`

const SloganText = styled(Text)`
  font-family: Poppins Latin Medium, serif;
  font-weight: bold; /*设置字体粗细*/
  width: 200px;
  text-align: center;
  line-height: 1.1;
  -webkit-text-stroke: 1px #e81d00; /*文字描边*/
  -webkit-text-fill-color: white; /*设置文字的填充颜色*/
  font-size: 20px; /*设置字体大小*/

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 380px;
    font-size: 30px; /*设置字体大小*/
  }
`

interface IgoInfo {
  igoTime: number
  igoStop: boolean
}

const stopDraggable = (e: any) => {
  if (e && e.preventDefault) {
    e.preventDefault()
  } else {
    window.event.returnValue = false
  }
}

const PSGMobileItem = () => {
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <PSGMobileContainer px={15}>
      <Text fontSize="22px" bold color="white" textTransform="uppercase" textAlign="center">
        {t('PSGPartnerTitle')}
      </Text>

      <Button height={30} onClick={() => router.push('/psg')} mt={40}>
        <Text fontSize="12px" bold maxWidth="500px">
          {t('PSGPartnerPage')}
        </Text>
      </Button>
      <ClickImage
        mt={20}
        src="/images/psg.jpg"
        height={400}
        width={710}
        onDragStart={(e) => stopDraggable(e)}
        onClick={() => router.push('/psg')}
      />
    </PSGMobileContainer>
  )
}

const ImageMobileItem = () => {
  const { t } = useTranslation()
  const textArray = [
    t('ImagineDesc1Mobile'),
    t('ImagineDesc2Mobile'),
    t('ImagineDesc3Mobile'),
    t('ImagineDesc4Mobile'),
    t('ImagineDesc5Mobile'),
  ]
  return (
    <Flex
      background="rgb(00, 00, 00, 0.5)"
      width="100%"
      height="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <NextImage src="/images/gens-assemble.png" layout="fill" objectFit="cover" className="zIndex-1" quality={10} />
      <Text fontSize="72px" color="white" textTransform="uppercase" fontWeight="100">
        {t('Imagine')}
      </Text>
      {textArray.map((textItem) => {
        return (
          <Text fontWeight="400" fontSize="12px" key={textItem}>
            {textItem}
          </Text>
        )
      })}
    </Flex>
  )
}

const ImageItem = () => {
  const { t, currentLanguage } = useTranslation()
  const { code } = currentLanguage
  const textArray = [
    t('ImagineDesc1Desktop'),
    t('ImagineDesc2Desktop'),
    t('ImagineDesc3Desktop'),
    t('ImagineDesc4Desktop'),
  ]
  return (
    <Flex
      mb={[null, null, '40vh', '40vh']}
      background="rgba(30, 33, 34, 0.8)"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      width="100%"
    >
      <Flex alignItems="flex-end" zIndex={11} flexDirection="column" alignSelf="flex-end" mb={40} mr={70}>
        <Text fontSize={code === 'en' ? '120px' : '100px'} color="white" textTransform="uppercase" fontWeight={100}>
          {t('Imagine')}
        </Text>
        <Flex color="white" mr={15} flexDirection="column" alignSelf="flex-end">
          {textArray.map((textItem) => {
            return (
              <Text fontWeight="400" fontSize="16px" key={textItem} textAlign="right">
                {textItem}
              </Text>
            )
          })}
        </Flex>
      </Flex>
      <NextImage src="/images/gens-assemble.png" height={480} width={320} alt="dagen Coming Soon" quality={10} />
    </Flex>
  )
}

const Countdown = ({ isMobile, countdown }) => {
  const countTextArray = [
    { days: countdown.day },
    { hours: countdown.hour },
    { min: countdown.minute },
    { sec: countdown.second },
  ]
  const { t, currentLanguage } = useTranslation()
  const igoStartInUrlPrefix = '/images/luca-is-coming-'
  const igoStartInUrl = `${igoStartInUrlPrefix}${currentLanguage.code}.svg`
  return (
    <CountdownContainer position="absolute" bottom={[-60, -60, -20, -20]}>
      <Flex mb={[35, 45, 60, 60]} width={[240, 240, 340, 340]} ml={[20, 20, 20, 20]}>
        <NextImage src={igoStartInUrl} width={340} height={80} quality={10} />
      </Flex>

      <CountdownItemContainer>
        <FlexGap gap={isMobile ? '35px' : '40px'}>
          {countTextArray.map((timeItem, index) => {
            return (
              <FlexGap key={index} gap="10px" flexDirection="column" alignItems="center">
                <BorderCountdownButton>{timeItem[Object.keys(timeItem)[0]]}</BorderCountdownButton>
                <Text fontSize={isMobile ? '14px' : '16px'} bold style={{ zoom: isMobile ? 0.9 : 1 }}>
                  {t(Object.keys(timeItem)[0])}
                </Text>
              </FlexGap>
            )
          })}
        </FlexGap>
      </CountdownItemContainer>
    </CountdownContainer>
  )
}

const SloganItem = ({ igoStatus }) => {
  const router = useRouter()
  const getStartImagePrefix = '/images/get-start-'
  const paperImagePrefix = '/images/whitepaper-'
  const { currentLanguage } = useTranslation()
  const { code } = currentLanguage
  const whitePaperUrl = LANGUAGE2CODE[code]
    ? `https://docs.dagen.life/${LANGUAGE2CODE[code]}/dagen/whitepaper/`
    : 'https://docs.dagen.life/dagen/whitepaper/'
  const startUrl = LANGUAGE2CODE[code]
    ? `https://docs.dagen.life/${LANGUAGE2CODE[code]}/reference/tutorial.html`
    : 'https://docs.dagen.life/reference/tutorial.html'

  const startImageUrl = `${getStartImagePrefix}${code}.svg`
  const whiteImageUrl = `${paperImagePrefix}${code}.svg`

  return (
    <>
      {igoStatus !== 0 && (
        // 370px  575  850   850px++

        <FlexGap
          mb={[0, 1, 20, 20]}
          width={['60%', '60%', '40%', '40%']}
          justifyContent="space-between"
          alignItems="center"
        >
          <ClickFlex as={NextLinkFromReactRouter} to={startUrl} mr={[20, 30, 0, 0]}>
            <NextImage src={startImageUrl} width={110} height={35} quality={10} />
          </ClickFlex>
          <ClickFlex as={NextLinkFromReactRouter} to={whitePaperUrl} mr={[10, 20, 0, 0]}>
            <NextImage src={whiteImageUrl} width={110} height={35} quality={10} />
          </ClickFlex>
        </FlexGap>
      )}

      {igoStatus === 0 && (
        <FlexGap
          mb={[-100, -108, -150, -150]}
          width={['60%', '53%', '53%', '53%']}
          justifyContent="space-between"
          alignItems="center"
        >
          <ClickFlex ml={[15, 10, 15, 15]} onClick={() => router.push(startUrl)}>
            <NextImage src={startImageUrl} width={120} height={35} quality={10} />
          </ClickFlex>
          <ClickFlex ml={[20, 18, 10, 0]} onClick={() => router.push(whitePaperUrl)}>
            <NextImage src={whiteImageUrl} width={120} height={35} quality={10} />
          </ClickFlex>
        </FlexGap>
      )}
    </>
  )
}

const PSGItem = () => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <PSGContainer mt={[null, null, '40vh', '40vh']} mb={[null, null, '10vh', '10vh']}>
      <ClickImage
        m={[null, null, 20, 20]}
        src="/images/psg.jpg"
        height={400}
        width={710}
        onDragStart={(e) => stopDraggable(e)}
        onClick={() => router.push('/psg')}
      />

      <Flex justifyContent="center" alignItems="center" zIndex={11} flexDirection="column" alignSelf="flex-center">
        <Text fontSize="26px" bold color="white" textTransform="uppercase" textAlign="center">
          {t('PSGPartnerTitle')}
        </Text>

        <Button height={[null, null, '64px', '48px']} onClick={() => router.push('/psg')} mt={40} maxWidth="500px">
          <Text bold fontSize="16px">
            {t('PSGPartnerPage')}
          </Text>
        </Button>
      </Flex>
    </PSGContainer>
  )
}

interface CountdownInfo {
  second: number
  minute: number
  hour: number
  day: number
}

const HomeSloganContainer: React.FC = () => {
  const router = useRouter()
  const { t, currentLanguage } = useTranslation()
  const { code } = currentLanguage
  const { isMobile } = useMatchBreakpoints()
  const joinIgoImagePrefix = '/images/welcome-to-luca-'
  const igoImageUrl = `${joinIgoImagePrefix}${code}.svg`

  const timer = useRef<NodeJS.Timeout>()
  // IGO活动结束之后              显示  gens-slogan-1.png status :0
  // IGO在倒计时                 显示  gens-slogan-2.png status :1
  // IGO在倒计时结束，活动为结束    显示  gens-slogan-3.png status :2
  const [igoStatus, setIgoStatus] = useState(-1)
  const [igoStop, setIgoStop] = useState(false)

  const genSloganBgUrl = ['/images/gens-slogan-1.png', '/images/gens-slogan-3.png', '/images/gens-slogan-3.png']
  const [igoTime, setIgoTime] = useState(1656039600000)
  const [countdown, setCountdown] = useState<CountdownInfo>({ second: 0, minute: 0, hour: 0, day: 0 })
  const { setting } = useSetting()

  useEffect(() => {
    if (setting) {
      setIgoStop(setting.igoStop)
      setIgoTime(setting.igoTime ? setting.igoTime : 0)
    }
  }, [setting])

  useEffect(() => {
    const calculate = () => {
      const dayTimestamp = 24 * 60 * 60 * 1000
      const hoursTimestamp = 60 * 60 * 1000
      const minuteTimestamp = 60 * 1000
      if (igoStop || igoTime === 0) {
        setIgoStatus(0)
        clearInterval(timer.current)
        return
      }

      const seconds = igoTime - new Date().getTime()
      if (seconds <= 0) {
        setIgoStatus(2)
        clearInterval(timer.current)
        return
      }
      setIgoStatus(1)
      const day = Math.floor(seconds / dayTimestamp)
      const hour = Math.floor((seconds - day * dayTimestamp) / hoursTimestamp)
      const minute = Math.floor((seconds - day * dayTimestamp - hour * hoursTimestamp) / minuteTimestamp)
      const second = Math.floor(
        (seconds - day * dayTimestamp - hour * hoursTimestamp - minute * minuteTimestamp) / 1000,
      )
      setCountdown({ second, minute, hour, day })
    }

    if (igoTime !== 0) {
      calculate()
      timer.current = setInterval(calculate, 1000)
    }
    return () => clearTimeout(timer.current)
  }, [igoStop, igoTime])

  const [width] = useWindowSize()
  return (
    <Container pb={[0, 0, 280, 280]} isMobile={isMobile}>
      <NextImage src="/images/index-bg.png" layout="fill" objectFit="cover" className="bg-img" quality={10} />
      {/* {igoStatus >= 0 && (
       <Flex alignItems="center" justifyContent="center" width="100%" height="100vh">
       <SloganContainer igoStatus={igoStatus} imageUrl={genSloganBgUrl[igoStatus]} widthScreen={width}>
       <SloganItem igoStatus={igoStatus} />
       {igoStatus === 2 && (
       <ClickFlex
       ml={30}
       onClick={() => router.push('/igo')}
       bottom={[40, 60, 160, 160]}
       width={[100, 200, 300, 300]}
       height={70}
       position="absolute"
       >
       <NextImage src={igoImageUrl} width={300} height={70} quality={10} alt="dagen igo start" />
       </ClickFlex>
       )}
       {igoStatus === 1 && <Countdown isMobile={isMobile} countdown={countdown} />}
       </SloganContainer>
       </Flex>
       )} */}

      {isMobile ? <ImageMobileItem /> : <ImageItem />}

      {!isMobile && (
        <Box height="80vh" zIndex={11}>
          <Flex flexDirection="column" alignItems="center">
            <Image
              loading="lazy"
              className="image-drag-none"
              mb={50}
              src="/images/dagen-yellow-icon.png"
              height={140}
              width={315}
              alt="dagen"
            />

            <Text pb={20} textAlign="center" fontWeight="400" color="white" maxWidth="680px" fontSize="16px">
              {t('dagenDesc1')}
            </Text>
            <Text py={20} textAlign="center" color="white" maxWidth="680px" fontSize="16px">
              {t('dagenDesc2')}
            </Text>
          </Flex>
        </Box>
      )}
    </Container>
  )
}

export default HomeSloganContainer
