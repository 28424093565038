import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@library/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useDagenApi } from 'hooks/useDagenApi'
import useToast from 'hooks/useToast'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useProfile } from 'state/profile/hooks'
import { useDagenToken } from 'state/user/hooks'
import { useMatchBreakpoints } from '@library/uikit'
import HomeBottomContainer from './components/HomeBottomContainer'
import HomeEconomicContainer from './components/HomeEconomicContainer'
import HomeGenContainer from './components/HomeGenContainer'
import HomeSloganContainer from './components/HomeSolganContainer'
import HomeSuspendedContainer from './components/HomeSuspendedContainer'
import GameHomeFirstMobilePage from './components/HomeGamePage/GameHomeFirstMobilePage'
import GameHomeFirstPage from './components/HomeGamePage/GameHomeFirstPage'
import GameHomeFirstPageNew from './components/HomeGamePage/GameHomeFirstPageNew'

const Home: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { toastInfo, toastSuccess, toastError, clear } = useToast()
  const { account, library } = useActiveWeb3React()
  const { loginDagen } = useAuth()
  const dagenApi = useDagenApi()
  const [dagenToken] = useDagenToken()
  const { data: session } = useSession()
  const { refresh } = useProfile()
  const [processed, setProcessed] = useState(false)
  const { isMobile } = useMatchBreakpoints()

  useEffect(() => {
    const ref = router.query.invitedId || router.query.invitedid || router.query.ref

    const timer = setTimeout(() => {
      if (ref && !session && !account) {
        toastInfo(t('Congrats! You got 1200e, Please login and confirm'))
      }
    }, 2000)

    if (processed) return () => clearTimeout(timer)
    if (ref && session && session.userId) {
      clear()
      setProcessed(true)
      // social refer
      dagenApi
        .post('refer', { id: session.userId, invitedId: ref, isManual: false })
        .then((res) => {
          if (res.data.success) {
            toastSuccess(t('Invitation success'))
            refresh()
          } else if (res.data.error) toastError(t(res.data.error))
        })
        .catch((e) => {
          toastError(e.message)
        })
    } else if (ref && dagenToken && account) {
      clear()
      setProcessed(true)
      // web3 refer
      library
        .getTransactionCount(account)
        .then((count) => {
          return dagenApi.post('refer', { id: account, invitedId: ref, isManual: false, txCount: count })
        })
        .then((res) => {
          if (res.data.success) {
            toastSuccess(t('Invitation success'))
            refresh()
          } else if (res.data.error) toastError(t(res.data.error))
        })
        .catch((e: any) => {
          toastError(e.message)
        })
    } else if (ref && !dagenToken && account) {
      toastInfo(t('Please sign to complete the refer'))
      loginDagen()
    }

    return () => clearTimeout(timer)
  }, [router, dagenApi, session, dagenToken, account])

  return (
    <>
      <PageMeta />
      {isMobile ? <GameHomeFirstMobilePage /> : <GameHomeFirstPageNew />}

      <HomeSuspendedContainer />
      <HomeSloganContainer />
      <HomeGenContainer />
      <HomeEconomicContainer />
      <HomeBottomContainer />
    </>
  )
}

export default Home
