import { useTranslation } from '@library/localization'
import React, { useEffect, useState } from 'react'
import { Button, Flex, Text, useMatchBreakpoints } from '@library/uikit'
import styled from 'styled-components'
import NextImage from 'next/image'
import { MENU_HEIGHT } from '@library/uikit/src/widgets/Menu/config'
import { imageList } from '@common/constans'
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react'
import useWindowSize from '../../../../hooks/useWindowSize'
import useLogin from '../../../../hooks/game/useLogin'
import { FlexGap } from '../../../../components/Layout/Flex'

const Container = styled(Flex)<{ image?: string; ratio?: number }>`
  position: relative;
  flex-direction: column;
  align-items: center;
`
export const StartButton = styled(Button)<{ ratio: number; color: string }>`
  width: ${({ ratio }) => `${Math.floor(ratio * 240)}px`};
  height: ${({ ratio }) => `${Math.floor(ratio * 96)}px`};
  border-radius: ${({ ratio }) => `${Math.floor(ratio * 100)}px`};
  background: ${({ color }) => color};
  border: ${({ ratio }) => `${Math.floor(ratio * 3)}px`} solid #ffffff;
`

export const StartTitle = styled(Text)<{ ratio?: number }>`
  z-index: 10;
  font-size: ${({ ratio }) => `${Math.floor(ratio * 30)}px`};
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
  color: #ffffff;
`

const ItemContainer = styled(Flex)<{ ratio: number }>`
  z-index: 10;
  width: ${({ ratio }) => `${Math.floor(ratio * 960)}px`};
  height: ${({ ratio }) => `${Math.floor(ratio * 540)}px`};
  position: relative;

  .item-image {
    border-radius: ${({ ratio }) => `${Math.floor(ratio * 32)}px`};
  }
`

interface GameItemContainerProps {
  image: string
  buttonColor: string
  buttonDesc: string
  isFirst?: boolean
  ratio?: number
  upTitle?: string
  downTitle?: string
}

const GameItemContainer: React.FC<GameItemContainerProps> = ({
  ratio,
  image,
  isFirst,
  buttonColor,
  buttonDesc,
  upTitle,
  downTitle,
}) => {
  const { startLogin } = useLogin()
  const { t, currentLanguage } = useTranslation()
  const [startVideoUrl, setStartVideoUrl] = useState('')
  const videoPrefix = '/video/start-video'

  useEffect(() => {
    setStartVideoUrl(`${videoPrefix}-${currentLanguage.code}.mp4`)
  }, [currentLanguage])

  return (
    <ItemContainer ratio={ratio}>
      {isFirst && (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          position="relative"
          alignItems="center"
          borderRadius="40px"
        >
          <Player key={startVideoUrl} preload="none" poster={image}  borderRadius="40px">
            <source src={startVideoUrl} />
            <BigPlayButton position="center" />
            <ControlBar>
              <VolumeMenuButton vertical />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            </ControlBar>
          </Player>
        </Flex>
      )}

      {!isFirst && <NextImage src={image} layout="fill" objectFit="cover" className="bg-img item-image" quality={1} />}

      {!isFirst && (
        <Flex alignItems="center" justifyContent="center" position="absolute" width="100%" height="100%">
          <StartButton ratio={ratio} color={buttonColor} onClick={() => startLogin()}>
            {t(buttonDesc)}
          </StartButton>
        </Flex>
      )}

      {!isFirst && (
        <>
          <Flex width="100%" mt={38 * ratio} position="absolute" top={0} justifyContent="end">
            <Flex position="relative" width={459 * ratio} height={60 * ratio} alignItems="end" justifyContent="center">
              <NextImage
                src="/images/game/home/ink.svg"
                layout="fill"
                objectFit="cover"
                className="image-drag"
                quality={10}
              />
              <StartTitle ratio={ratio}>{t(upTitle)}</StartTitle>
            </Flex>
          </Flex>
          <Flex width="100%" mb={38 * ratio} position="absolute" bottom={0} justifyContent="start">
            <Flex
              position="relative"
              width={459 * ratio}
              height={60 * ratio}
              alignItems="start"
              justifyContent="center"
            >
              <NextImage
                src="/images/game/home/ink.svg"
                layout="fill"
                objectFit="cover"
                className="image-drag"
                quality={10}
              />
              <StartTitle ratio={ratio}>{t(downTitle)}</StartTitle>
            </Flex>
          </Flex>
        </>
      )}
    </ItemContainer>
  )
}

const GameHomeFirstPageNew: React.FC = () => {
  const [width] = useWindowSize()
  const [ratio, setRatio] = useState(1)
  const { isMobile } = useMatchBreakpoints()
  const bgList = new Array(3).fill(1)

  useEffect(() => {
    if (isMobile) return
    setRatio(Math.min(width / 1920, 1))
  }, [isMobile, width])

  return (
    <Container mt={MENU_HEIGHT}>
      {bgList.map((item, index) => {
        return (
          <Flex key={`bg_list_${index}`} position="relative" width="100%" height={1080 * ratio}>
            <NextImage
              src="/images/game/bg/bg-desktop-night@4x.png"
              layout="fill"
              objectFit="cover"
              className="image-drag"
              quality={10}
            />
          </Flex>
        )
      })}

      <FlexGap
        mt={142 * ratio}
        position="absolute"
        width="100%"
        height="100%"
        flexDirection="column"
        alignItems="center"
      >
        {imageList.map((item, index) => {
          return (
            <Flex flexDirection="column" key={`image_list_${item.image}_${index}`} alignItems="center">
              <GameItemContainer
                ratio={ratio}
                image={item.image}
                isFirst={item.isFirst}
                buttonColor={item.buttonColor}
                buttonDesc={item.buttonDesc}
                upTitle={item.upTitle}
                downTitle={item.downTitle}
              />
              {index + 1 < imageList.length && (
                <Flex zIndex={10} position="relative" width={120 * ratio} height={38 * ratio} my={28 * ratio}>
                  <NextImage
                    src="/images/game/home/arrow.svg"
                    layout="fill"
                    objectFit="cover"
                    className="bg-img item-image"
                    quality={10}
                  />
                </Flex>
              )}
            </Flex>
          )
        })}
      </FlexGap>
    </Container>
  )
}

export default GameHomeFirstPageNew
