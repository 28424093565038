import { homeImgInfoList, imageList } from '@common/constans'
import { useTranslation } from '@library/localization'
import { Flex } from '@library/uikit'
import { HOME_MENU_HEIGHT } from '@library/uikit/src/widgets/Menu/config'
import NextImage from 'next/image'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react'
import useLogoutManager from '../../../../hooks/game/useLogin'
import { StartButton, StartTitle } from './GameHomeFirstPageNew'

const Container = styled(Flex)<{ image?: string }>`
  width: 100%;
  gap: 30px;
  flex-direction: column;
`

const ImageItemContainer = styled(Flex)<{ image?: string }>`
  width: 100%;
  height: 280px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #89bd00;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.5));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  justify-content: center;

  .image {
    -webkit-user-drag: none;
  }
`

const GameHomeFirstMobilePage: React.FC = () => {
  const { startLogin } = useLogoutManager()
  const [startVideoUrl, setStartVideoUrl] = useState('')
  const { t, currentLanguage } = useTranslation()

  const videoPrefix = '/video/start-video'

  useEffect(() => {
    setStartVideoUrl(`${videoPrefix}-${currentLanguage.code}.mp4`)
  }, [currentLanguage])

  return (
    <Container mt={HOME_MENU_HEIGHT + 70}>
      {imageList.map((item, index) => {
        return (
          <Flex position="relative" justifyContent="center">
            {index !== 0 && (
              <Flex
                zIndex={10}
                alignItems="center"
                justifyContent="center"
                position="absolute"
                width="100%"
                height="100%"
              >
                <StartButton color={item.buttonColor} onClick={() => startLogin()}>
                  {t(item.buttonDesc)}
                </StartButton>
              </Flex>
            )}

            <ImageItemContainer key={`imageInfoList_${item.image}_${index}`}>
              {index !== 0 && (
                <NextImage src={item.image} layout="fill" objectFit="cover" className="image" quality={20} />
              )}
              {index === 0 && (
                <Flex width="100%" height="100%" justifyContent="center" position="relative" alignItems="center">
                  <Player key={startVideoUrl} preload="none" poster={item.image}>
                    <source src={startVideoUrl} />
                    <BigPlayButton position="center" />
                    <ControlBar>
                      <VolumeMenuButton vertical />
                      <CurrentTimeDisplay order={4.1} />
                      <TimeDivider order={4.2} />
                      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                    </ControlBar>
                  </Player>
                </Flex>
              )}

              {index !== 0 && (
                <>
                  <Flex width="100%" position="absolute" top="10px" justifyContent="end">
                    <Flex position="relative" width={230} height={30} alignItems="end" justifyContent="center">
                      <NextImage
                        src="/images/game/home/ink.svg"
                        layout="fill"
                        objectFit="cover"
                        className="image-drag"
                        quality={10}
                      />
                      <StartTitle>{t(item.upTitle)}</StartTitle>
                    </Flex>
                  </Flex>
                  <Flex width="100%" position="absolute" bottom="10px" justifyContent="start">
                    <Flex position="relative" width={230} height={30} alignItems="start" justifyContent="center">
                      <NextImage
                        src="/images/game/home/ink.svg"
                        layout="fill"
                        objectFit="cover"
                        className="image-drag"
                        quality={10}
                      />
                      <StartTitle>{t(item.downTitle)}</StartTitle>
                    </Flex>
                  </Flex>
                </>
              )}
            </ImageItemContainer>

            {index !== homeImgInfoList.length - 1 && (
              <Flex position="absolute" bottom="-45px" zIndex={10}>
                <NextImage src="/images/game/home/arrow.svg" width={60} height={55} />
              </Flex>
            )}
          </Flex>
        )
      })}
    </Container>
  )
}

export default GameHomeFirstMobilePage

export const HomeLoginBtnContainer = styled(Flex)`
  font-size: 18px;
  height: 56px;
  max-width: 80%;
  background: #4553ec;
  border: 1px solid #ffffff;
  border-radius: 100px;
  align-items: center;
  padding: 5px 20px;

  cursor: pointer;

  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: 0.65;
  }

  &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: 0.85;
    transform: translateY(1px);
    box-shadow: none;
  }
`
