import { useTranslation } from '@library/localization'
import { Flex, useMatchBreakpoints } from '@library/uikit'
import { LANGUAGE2CODE } from '@library/uikit/src/widgets/Menu/config'
import { MenuContext } from '@library/uikit/src/widgets/Menu/context'
import styled from 'styled-components'

import useWallet from 'hooks/useWallet'
import React, { useContext } from 'react'
import ClockIn from '../../../../components/Menu/UserMenu/ClockIn'

const ClockInContainer = styled(Flex)<{ drawerOpen: boolean }>`
  position: absolute;
  left: 1%;
  top: 130px;

  ${({ theme }) => theme.mediaQueries.sm} {
    top: 160px;
    z-index: 10;
  }
`

const HomeSuspendedContainer: React.FC = () => {
  const { walletAddress } = useWallet()
  const { isMobile } = useMatchBreakpoints()
  const { currentLanguage, t } = useTranslation()
  const { code } = currentLanguage
  const { drawerOpen } = useContext(MenuContext)

  const stopDraggable = (e: any) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    } else {
      window.event.returnValue = false
    }
  }
  const openURL = (url: string) => window.open(url, '_blank')?.focus()
  const docUrl = `https://docs.dagen.life/${LANGUAGE2CODE[code]}${LANGUAGE2CODE[code] ? '/' : ''}reference/invite.html`

  return (
    <Flex>
      {/* {!isMobile && (
        <Container className="animate__animated animate__shakeY animate__infinite">
          <NextImage
            onDragStart={(e) => stopDraggable(e)}
            src="/images/suspension.png"
            height={230}
            width={212}
            quality={50}
          />
          <Flex alignItems="center" zIndex="3" flexDirection="column" position="absolute" left="34%" top="18%">
            <Text bold onClick={() => openURL(discordUrl)}>
              {t('Join Discord')}
            </Text>
            <NextImage onClick={() => openURL(discordUrl)} src="/images/discord.svg" height={32} width={32} />
          </Flex>
        </Container>
      )} */}

      <ClockInContainer drawerOpen={drawerOpen}>
        <ClockIn accountPath={walletAddress} />
      </ClockInContainer>
    </Flex>
  )
}

export default HomeSuspendedContainer
