import { Box, Flex, Image, Text, TextItem, useMatchBreakpoints } from '@library/uikit'
import { TextTitle } from '@library/uikit/src/components/Text/Text'
import { ColumnCenter } from 'components/Layout/Column'
import { useTranslation } from '@library/localization'
import NextImage from 'next/image'
import React, { useEffect } from 'react'
import { Parallax } from 'react-parallax'
import { useTheme } from 'styled-components'

const HomeGenContainer: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isMobile } = useMatchBreakpoints()
  const gensDesc = [t('GensDesc1Mobile'), t('GensDesc2Mobile'), t('GensDesc3Mobile'), t('GensDesc4Mobile')]

  const nextImages = {
    genBallBg1: {
      id: 'genBallBg1',
      zIndex: -2,
      speed: 80,
      initPosition: -20,
      img: '/images/gens-ball-bg-1.png',
      dom: {} as any,
    },
    genBallBg2: {
      id: 'genBallBg2',
      zIndex: -1,
      speed: 160,
      initPosition: -60,
      img: '/images/gens-ball-bg-2.png',
      dom: {} as any,
    },
  }
  let nextImageInit = false

  useEffect(() => {
    if (!nextImageInit) {
      Object.keys(nextImages).forEach((item) => {
        nextImages[item].dom = document.getElementById(item) as any
        nextImages[item].dom.style.zIndex = nextImages[item].zIndex
      })
      nextImageInit = true
    }
  })

  const setPercentage = (percentage): void => {
    if (!nextImageInit) return
    Object.keys(nextImages).forEach((item) => {
      nextImages[item].dom.style.bottom = `${nextImages[item].initPosition + percentage * nextImages[item].speed}vh`
    })
  }

  return (
    <Parallax
      style={{ height: `${!isMobile ? '140vh' : ''}` }}
      renderLayer={(percentage) => (
        <Flex background={theme.colors.backgroundAlt2} className="main">
          {setPercentage(percentage)}

          <NextImage
            id={nextImages.genBallBg1.id}
            src={nextImages.genBallBg1.img}
            layout="fill"
            objectFit="cover"
            quality={70}
            className="image-bg-opacity"
          />
          <NextImage
            id={nextImages.genBallBg2.id}
            src={nextImages.genBallBg2.img}
            layout="fill"
            objectFit="cover"
            quality={70}
            className="image-bg-opacity"
          />

          <Flex
            position={isMobile ? null : 'absolute'}
            style={!isMobile ? { bottom: `${-110 + percentage * 160}vh` } : null}
            flexDirection="column"
            my={['20vh', '20vh', null, null]}
            alignItems="center"
            zIndex={11}
          >
            <Box mb={60} display={['block', 'block', 'none', 'none']}>
              <Flex flexDirection="column" alignItems="center" justifyContent="center">
                <Image
                  loading="lazy"
                  className="image-drag-none"
                  mb={50}
                  src="/images/dagen-yellow-icon.png"
                  height={66}
                  width={150}
                  alt="dagen Coming Soon"
                />

                <Text bold pb="16px" textAlign="center" color="white" maxWidth="70%" fontSize="12px">
                  {t('dagenDesc1')}
                </Text>

                <Text bold py="16px" textAlign="center" color="white" maxWidth="70%" fontSize="12px">
                  {t('dagenDesc2')}
                </Text>
              </Flex>
            </Box>

            <TextTitle style={{ zIndex: 999 }}>{t('Gens')}</TextTitle>

            {isMobile ? (
              <ColumnCenter color="white" style={{ marginBottom: '50px' }}>
                {gensDesc.map((textItem, index) => {
                  return <TextItem key={index}>{textItem}</TextItem>
                })}
              </ColumnCenter>
            ) : (
              <ColumnCenter color="white" style={{ marginBottom: '50px' }}>
                <Text textAlign="center" fontWeight={400} maxWidth={['90%', '90%', '650px', '650px']} fontSize="16px">
                  {t('GensDesc')}
                </Text>
              </ColumnCenter>
            )}
            <NextImage src="/images/gens-bg.png" width={659} height={309} quality={80} />
          </Flex>
        </Flex>
      )}
    />
  )
}

export default HomeGenContainer
